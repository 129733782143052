
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react'; 
import "../styles/HeaderSignup.css"; 
import { Link } from 'react-router-dom';



const PublicHeader: React.FC = () => {
  const { loginWithRedirect } = useAuth0(); // Auth0 hook for login

  // Ensure handleLogin is correctly defined inside the component
  const handleLogin = () => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin, 
      },
    });
  };

  return (

    <header className="bg-white shadow p-4 border-b border-gray-300">
      <div className="container mx-auto flex justify-between items-start">
     
      <h1 className="text-xl font-bold ml-4 flex items-center">
          <img 
            src="/favicon-32x32.png" 
            alt="CB_ViSTA Logo" 
            className="w-8 h-8 mr-2" 
          />
          CB_ViSTA
        </h1>
        <div>
          {/* Trigger login with Auth0 */}
          <button
            onClick={handleLogin} 
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md mr-2 hover:bg-gray-300"
          >
            Login
          </button>
          <Link to="/Signup">
            <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Sign Up
            </button>
          </Link>
        </div>
      </div>
    </header>
  
  );
};

export default PublicHeader;

