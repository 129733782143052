




// import React, { useEffect, useState } from 'react';
// import PageLayout from '../../../components/PageLayout';
// import { Link } from 'react-router-dom';

// type StatusPage = {
//   custom_url: string;
//   friendly_name: string;
//   id: number;
//   monitors: number[] | [];
//   sort: number;
//   standard_url: string;
//   status: number;
// };

// type StatusData = {
//   stat: string;
//   pagination: {
//     limit: number;
//     offset: number;
//     total: number;
//   };
//   psps: StatusPage[];
// };

// const Status: React.FC = () => {
//   const [statusPages, setStatusPages] = useState<StatusPage[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);

//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentStatusPage, setCurrentStatusPage] = useState<StatusPage | null>(null);
//   const [friendlyName, setFriendlyName] = useState('');
//   const [customUrl, setCustomUrl] = useState('');
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [updateSuccess, setUpdateSuccess] = useState(false);

//   const apiUrl = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     const fetchStatusData = async () => {
//       try {
//         const res = await fetch(`${apiUrl}/api/uptime/status`);
//         if (!res.ok) {
//           throw new Error('Failed to fetch status data');
//         }
//         const data: StatusData = await res.json();
//         setStatusPages(data.psps || []);
//       } catch (err) {
//         setError('Failed to fetch data');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchStatusData();
//   }, [apiUrl]);

//   const handleEdit = (statusPage: StatusPage) => {
//     setCurrentStatusPage(statusPage);
//     setFriendlyName(statusPage.friendly_name);
//     setCustomUrl(statusPage.custom_url);
//     setUpdateSuccess(false);
//     setIsModalOpen(true);
//   };

//   const handleUpdate = async () => {
//     if (!currentStatusPage) return;
//     setIsUpdating(true);
//     setUpdateSuccess(false);
//     try {
//       const response = await fetch(`${apiUrl}/api/uptime/status/${currentStatusPage.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           friendly_name: friendlyName,
//           custom_url: customUrl,
//           monitor_ids: currentStatusPage.monitors || [],
//         }),
//       });

//       const result = await response.json();
//       if (response.ok) {
//         setStatusPages(
//           statusPages.map((status) =>
//             status.id === currentStatusPage.id
//               ? { ...status, friendly_name: friendlyName, custom_url: customUrl }
//               : status
//           )
//         );
//         setUpdateSuccess(true);
//         setTimeout(() => setIsModalOpen(false), 2000);
//       } else {
//         alert('Failed to update status page: ' + (result.message || 'Unknown error'));
//       }
//     } catch (error) {
//       console.error('Error updating status page:', error);
//       alert('Error updating status page');
//     } finally {
//       setIsUpdating(false);
//     }
//   };

//   const handleDelete = async (id: number) => {
//     const isConfirmed = window.confirm('Are you sure you want to delete this status page?');
//     if (!isConfirmed) return;

//     try {
//       const response = await fetch(`${apiUrl}/api/uptime/status/${id}`, {
//         method: 'DELETE',
//       });

//       if (response.ok) {
//         setStatusPages(statusPages.filter((status) => status.id !== id));
//         alert('Status page deleted successfully');
//       } else {
//         alert('Failed to delete status page');
//       }
//     } catch (error) {
//       console.error('Error deleting status page:', error);
//       alert('Error deleting status page');
//     }
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//     setFriendlyName('');
//     setCustomUrl('');
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <PageLayout>
//       <div className="flex flex-col md:flex-row h-screen bg-gray-100">
//         <div className="p-4 bg-gray rounded-lg shadow-md w-full md:max-w-5xl mx-auto mt-6">
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-3xl font-bold">Status Pages</h1>
//             <Link to="/monitorminds/statuspage">
//               <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
//                 + Create Status Page
//               </button>
//             </Link>
//           </div>
//           <table className="w-full table-auto border-collapse border border-gray-300 text-sm">
//             <thead>
//               <tr className="bg-gray-400">
//                 <th className="px-4 py-2 text-left !text-lg border-b">Name</th>
//                 <th className="px-4 py-2 text-left !text-lg border-b">Monitors</th>
//                 <th className="px-4 py-2 text-left !text-lg border-b">Standard URL</th>
//                 <th className="px-4 py-2 text-left !text-lg border-b">Custom URL</th>
//                 <th className="px-4 py-2 text-left  !text-lg border-b">Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {statusPages.map((status) => (
//                 <tr key={status.id} className="hover:bg-gray-100 border-2 border-gray-300">
//                   <td className="px-4 py-2 border-b">{status.friendly_name}</td>
//                   <td className="px-4 py-2 border-b">{status.monitors.length}</td>
//                   <td className="px-4 py-2 border-b">
//                     <a href={status.standard_url} className="text-blue-500" target="_blank" rel="noopener noreferrer">
//                       View
//                     </a>
//                   </td>
//                   <td className="px-4 py-2 border-b">
//                     {status.custom_url ? (
//                       <a href={status.custom_url} className="text-blue-500" target="_blank" rel="noopener noreferrer">
//                         View
//                       </a>
//                     ) : (
//                       'No custom URL'
//                     )}
//                   </td>
//                   <td className="px-4 py-2 border-b">
//                     <button
//                       onClick={() => handleEdit(status)}
//                       className="px-2 py-1 bg-gray-500 text-white rounded-lg hover:bg-yellow-600 mr-2"
//                     >
//                       Edit
//                     </button>
//                     <button
//                       onClick={() => handleDelete(status.id)}
//                       className="px-2 py-1 bg-gray-500 text-white rounded-lg hover:bg-red-600"
//                     >
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         {isModalOpen && (
//           <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
//             <div className="bg-white p-6 rounded-lg w-11/12 md:w-1/3">
//               <h2 className="text-xl font-semibold mb-4">Edit Status Page</h2>
//               <div className="mb-4">
//                 <label className="block text-gray-700 mb-2">Name</label>
//                 <input
//                   type="text"
//                   value={friendlyName}
//                   onChange={(e) => setFriendlyName(e.target.value)}
//                   className="w-full px-4 py-2 border rounded-lg"
//                   disabled={isUpdating}
//                 />
//               </div>
//               <div className="mb-4">
//                 <label className="block text-gray-700 mb-2">Custom URL</label>
//                 <input
//                   type="text"
//                   value={customUrl}
//                   onChange={(e) => setCustomUrl(e.target.value)}
//                   className="w-full px-4 py-2 border rounded-lg"
//                   disabled={isUpdating}
//                 />
//               </div>
//               {updateSuccess && (
//                 <p className="text-green-600 text-center mb-4">
//                   Status page updated successfully!
//                 </p>
//               )}
//               <div className="flex justify-end">
//                 <button
//                   onClick={handleCancel}
//                   className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 mr-2"
//                   disabled={isUpdating}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   onClick={handleUpdate}
//                   className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-blue-300 flex items-center"
//                   disabled={isUpdating}
//                 >
//                   {isUpdating ? (
//                     <svg
//                       className="animate-spin h-5 w-5 text-white mr-2"
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                     >
//                       <circle
//                         className="opacity-25"
//                         cx="12"
//                         cy="12"
//                         r="10"
//                         stroke="currentColor"
//                         strokeWidth="4"
//                       ></circle>
//                       <path
//                         className="opacity-75"
//                         fill="currentColor"
//                         d="M4 12a8 8 0 018-8V0C3.58 0 0 5.58 0 12h4zm2 5.29A7.97 7.97 0 014 12H0c0 3.87 2.18 7.2 5.29 8.71L6 17.29z"
//                       ></path>
//                     </svg>
//                   ) : null}
//                   Update
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </PageLayout>
//   );
// };

// export default Status;




import React, { useEffect, useState } from 'react';
import PageLayout from '../../../components/PageLayout';
import { Link } from 'react-router-dom';

type StatusPage = {
  custom_url: string;
  friendly_name: string;
  id: number;
  monitors: number[] | [];
  sort: number;
  standard_url: string;
  status: number;
};

type StatusData = {
  stat: string;
  pagination: {
    limit: number;
    offset: number;
    total: number;
  };
  psps: StatusPage[]; 
};

const Status: React.FC = () => {
  const [statusPages, setStatusPages] = useState<StatusPage[]>([]);
  const [, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStatusPage, setCurrentStatusPage] = useState<StatusPage | null>(null);
  const [friendlyName, setFriendlyName] = useState('');
  const [customUrl, setCustomUrl] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStatusData = async () => {
      try {
        const res = await fetch(`${apiUrl}/api/uptime/status`);
        if (!res.ok) {
          throw new Error('Failed to fetch status data');
        }
        const data: StatusData = await res.json();
        setStatusPages(data.psps || []);
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchStatusData();
  }, [apiUrl]);

  const handleEdit = (statusPage: StatusPage) => {
    setCurrentStatusPage(statusPage);
    setFriendlyName(statusPage.friendly_name);
    setCustomUrl(statusPage.custom_url);
    setUpdateSuccess(false);
    setIsModalOpen(true);
  };

  const handleUpdate = async () => {
    if (!currentStatusPage) return;
    setIsUpdating(true);
    setUpdateSuccess(false);
    try {
      // Convert monitor_ids to strings
      const monitorIdsAsStrings = currentStatusPage.monitors.map(String);

      const response = await fetch(`${apiUrl}/api/uptime/status/${currentStatusPage.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          friendly_name: friendlyName,
          custom_url: customUrl,
          monitor_ids: monitorIdsAsStrings, // Updated monitor_ids
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setStatusPages(
          statusPages.map((status) =>
            status.id === currentStatusPage.id
              ? { ...status, friendly_name: friendlyName, custom_url: customUrl }
              : status
          )
        );
        setUpdateSuccess(true);
        setTimeout(() => setIsModalOpen(false), 2000);
      } else {
        alert('Failed to update status page: ' + (result.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error updating status page:', error);
      alert('Error updating status page');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async (id: number) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this status page?');
    if (!isConfirmed) return;

    try {
      const response = await fetch(`${apiUrl}/api/uptime/status/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setStatusPages(statusPages.filter((status) => status.id !== id));
        alert('Status page deleted successfully');
      } else {
        alert('Failed to delete status page');
      }
    } catch (error) {
      console.error('Error deleting status page:', error);
      alert('Error deleting status page');
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFriendlyName('');
    setCustomUrl('');
  };

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <PageLayout>
      <div className="flex flex-col md:flex-row h-screen bg-gray-100">
        <div className="p-4 bg-gray rounded-lg shadow-md w-full md:max-w-5xl mx-auto mt-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-3xl font-bold">Status Pages</h1>
            <Link to="/monitorminds/statuspage">
              <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                + Create Status Page
              </button>
            </Link>
          </div>
          <table className="w-full table-auto border-collapse border border-gray-300 text-sm">
            <thead>
              <tr className="bg-gray-400">
                <th className="px-4 py-2 text-left !text-lg border-b">Name</th>
                <th className="px-4 py-2 text-left !text-lg border-b">Monitors</th>
                <th className="px-4 py-2 text-left !text-lg border-b">Standard URL</th>
                <th className="px-4 py-2 text-left !text-lg border-b">Custom URL</th>
                <th className="px-4 py-2 text-left  !text-lg border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {statusPages.map((status) => (
                <tr key={status.id} className="hover:bg-gray-100 border-2 border-gray-300">
                  <td className="px-4 py-2 border-b">{status.friendly_name}</td>
                  <td className="px-4 py-2 border-b">{status.monitors.length}</td>
                  <td className="px-4 py-2 border-b">
                    <a href={status.standard_url} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  </td>
                  <td className="px-4 py-2 border-b">
                    {status.custom_url ? (
                      <a href={status.custom_url} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                        View
                      </a>
                    ) : (
                      'No custom URL'
                    )}
                  </td>
                  <td className="px-4 py-2 border-b">
                    <button
                      onClick={() => handleEdit(status)}
                      className="px-2 py-1 bg-gray-500 text-white rounded-lg hover:bg-yellow-600 mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(status.id)}
                      className="px-2 py-1 bg-gray-500 text-white rounded-lg hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-11/12 md:w-1/3">
              <h2 className="text-xl font-semibold mb-4">Edit Status Page</h2>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Name</label>
                <input
                  type="text"
                  value={friendlyName}
                  onChange={(e) => setFriendlyName(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg"
                  disabled={isUpdating}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Custom URL</label>
                <input
                  type="text"
                  value={customUrl}
                  onChange={(e) => setCustomUrl(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg"
                  disabled={isUpdating}
                />
              </div>
              {updateSuccess && (
                <p className="text-green-600 text-center mb-4">
                  Status page updated successfully!
                </p>
              )}
              <div className="flex justify-end">
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 mr-2"
                  disabled={isUpdating}
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdate}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-blue-300 flex items-center"
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <svg
                      className="animate-spin h-5 w-5 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <circle cx="12" cy="12" r="10" strokeWidth="4" stroke="currentColor" />
                      <path
                        fill="none"
                        d="M4 12a8 8 0 118 8"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                    </svg>
                  ) : (
                    'Update'
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default Status;
