import React from 'react';
import "../styles/layout.css";

import PublicHeader from './PublicHeader';





const PageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="layout-wrapper">
      <header className="layout-header">
       <PublicHeader/>
      </header>
      <main className="layout-main">{children}</main>
    </div>
  );
};

export default PageLayout;

