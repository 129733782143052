import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PageLayout from "../../components/PageLayout";

const Profile: React.FC = () => {
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [idToken, setIdToken] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        // Get ID token
        const idTokenClaims = await getIdTokenClaims();
        if (idTokenClaims) {
          setIdToken(idTokenClaims.__raw); // __raw contains the raw JWT
        }

        // Get Access token
        const accessToken = await getAccessTokenSilently();
        setAccessToken(accessToken);
      } catch (error) {
        console.error("Error fetching tokens:", error);
      }
    };

    if (isAuthenticated) {
      fetchTokens();
    }
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims]);

  if (!isAuthenticated) {
    return (
      <PageLayout>
        <div>Please log in to view your profile.</div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className="p-4">
        <h2 className="text-2xl mb-4">Profile</h2>
        <img
          src={user?.picture}
          alt={user?.name}
          className="w-24 h-24 rounded-full mb-4"
        />
        <p>
          <strong>Name:</strong> {user?.name}
        </p>
        <p>
          <strong>Email:</strong> {user?.email}
        </p>
        <div className="mt-4">
          <h3 className="text-xl mb-2">Tokens</h3>
          <p>
            <strong>ID Token:</strong>{" "}
            <span className="break-all">{idToken || "Loading..."}</span>
          </p>
          <p>
            <strong>Access Token:</strong>{" "}
            <span className="break-all">{accessToken || "Loading..."}</span>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export default Profile;