import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/PageLayout";

interface CostReport {
  title: string;
  created_at: string;
  folder_token: string | null;
  settings: {
    aggregate_by: string;
    amortize: boolean;
    include_credits: boolean;
    include_discounts: boolean;
    include_refunds: boolean;
    include_tax: boolean;
    unallocated: boolean;
  };
}

const CostReports: React.FC = () => {
  const [costReports, setCostReports] = useState<CostReport[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const api_url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCostReports = async () => {
      if (!api_url) {
        console.error("API URL is not defined in the environment variables.");
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${api_url}/api/vantage/cost_reports`);
        const data = await response.json();
        setCostReports(data.cost_reports || []);
      } catch (error) {
        console.error("Error fetching cost reports:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCostReports();
  }, [api_url]);

  return (
    <PageLayout>
    <div className="flex flex-col md:flex-row">
    
      <div className="w-fullbg-gray-800 text-white">
        
      </div>

      {/* Main Content */}
      <div className="md:w-3/4 flex-1 p-4 h-screen bg-gray-100">
        <h1 className="text-2xl font-bold mb-4">Cost Reports</h1>
        <div className="overflow-x-auto">
          {isLoading ? (
            <p className="text-center">Loading...</p>
          ) : costReports.length > 0 ? (
            <table className="min-w-full bg-white border border-gray-200 rounded-lg">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Name</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Created By</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Created On</th>
                </tr>
              </thead>
              <tbody>
                {costReports.map((report, index) => (
                  <tr key={index} className="border-t">
                    <td className="px-4 py-2 text-sm text-gray-800">{report.title || "N/A"}</td>
                    <td className="px-4 py-2 text-sm text-gray-800">{report.folder_token || "-"}</td>
                    <td className="px-4 py-2 text-sm text-gray-800">{report.created_at || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center">No cost reports found.</p>
          )}
        </div>
      </div>
    </div>
    </PageLayout>
  );
};

export default CostReports;