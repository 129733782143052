



import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import PageLayout from '../../../components/PageLayout';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

interface Monitor {
  id: number;
  friendly_name: string;
}

const CreateStatusPage: React.FC = () => {
  const [monitorIds, setMonitorIds] = useState<number[]>([]);
  const [monitors, setMonitors] = useState<Monitor[]>([]);
  const [friendlyName, setFriendlyName] = useState('');
  const [customDomain, setCustomDomain] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate(); // Initialize useNavigate

  // Fetch monitors from API and populate the dropdown
  useEffect(() => {
    const loadMonitors = async () => {
      try {
        const response = await fetch(`${api_url}/api/uptime/monitor`);
        if (!response.ok) throw new Error('Failed to fetch monitors');
        const data = await response.json();
        setMonitors(
          data.monitors.map((monitor: any) => ({
            id: monitor.id,
            friendly_name: monitor.friendly_name,
          }))
        );
      } catch (error) {
        console.error('Error loading monitors:', error);
        setError('Failed to load monitors.');
      }
    };
    loadMonitors();
  }, [api_url]);

  // Function to handle status page creation
  const createStatusPage = async () => {
    setError(null);
    setMessage(null);

    const payload = {
      friendly_name: friendlyName, // Use 'friendly_name' as the key
      custom_domain: customDomain,
      monitor_ids: monitorIds.map(String), // Convert monitor IDs to strings
    };

    try {
      const response = await fetch(`${api_url}/api/uptime/status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setMessage('Status page created successfully!');
      } else {
        const errorData = await response.json();
        setError('Failed to create status page.');
        console.error('Failed to create status page:', errorData);
      }
    } catch (error) {
      console.error('Error creating status page:', error);
      setError('Error creating status page.');
    }
  };

  // Monitor selection handlers for Multiselect
  const onSelect = (selectedList: Monitor[], selectedItem: Monitor) => {
    setMonitorIds([...monitorIds, selectedItem.id]);
  };

  const onRemove = (selectedList: Monitor[], removedItem: Monitor) => {
    setMonitorIds(monitorIds.filter((id) => id !== removedItem.id));
  };

  // Function to navigate to the Status page when "View Status" is clicked
  const viewStatus = () => {
    navigate('/monitorminds/status');
  };

  return (
    <PageLayout>
      <div className="min-h-screen bg-gray-50 flex items-center justify-center bg-gray-200 text-gray-800 p-4">
        <div className="container max-w-lg w-full p-6 bg-gray-200 border border-gray-300 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-6 text-center">Create Status Page</h2>

          {/* Status Page Name */}
          <label htmlFor="friendly_name" className="block font-medium mt-4">
            Name of the Status Page
          </label>
          <input
            type="text"
            id="friendly_name"
            placeholder="E.g. Your Brand"
            value={friendlyName}
            onChange={(e) => setFriendlyName(e.target.value)}
            className="w-full p-2 mt-2 rounded border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-300"
          />

          {/* Custom Domain */}
          <label htmlFor="custom_domain" className="block font-medium mt-4">
            Custom Domain
          </label>
          <input
            type="text"
            id="custom_domain"
            placeholder="E.g. status.yourdomain.com"
            value={customDomain}
            onChange={(e) => setCustomDomain(e.target.value)}
            className="w-full p-2 mt-2 rounded border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-300"
          />

          {/* Monitor Selection using Multiselect */}
          <label htmlFor="monitor_ids" className="block font-medium mt-4">
            Monitors on Status Page
          </label>
          <Multiselect
            options={monitors}
            selectedValues={monitors.filter((monitor) => monitorIds.includes(monitor.id))}
            onSelect={onSelect}
            onRemove={onRemove}
            displayValue="friendly_name"
            className="w-full mt-2 border border-gray-300 rounded"
          />
          <small className="block text-black-500 font-semibold mb-4">You can add monitors by name or URL.</small>

          <button
            onClick={createStatusPage}
            className="w-full py-2 mt-6 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
          >
            Create Status Page
          </button>

          {/* Error and Success Messages */}
          {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
          {message && (
            <div className="mt-4 text-green-600 text-center">
              <p>{message}</p>
              {/* View Status Button */}
              <button
                onClick={viewStatus}
                className="mt-4 py-2 px-6 bg-green-500 text-white rounded hover:bg-blue-600"
              >
                View Status
              </button>
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default CreateStatusPage;
