import React from "react";
import { BrowserRouter } from "react-router-dom";
// import Sidebar from "./components/Sidebar";
import AppRoutes from "./routes"; // Import the routes file

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div>
        {/* <Sidebar /> */}
        <div>
          <AppRoutes /> {/* Use the routes here */}
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
