


import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageLayout from '../../../components/PageLayout';

interface Monitor {
  friendly_name: string;
  monitor_url: string;
}

const Create: React.FC = () => {
  const [friendlyName, setFriendlyName] = useState('');
  const [monitorUrl, setMonitorUrl] = useState('');
  const [interval, setInterval] = useState<number>(5);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [existingMonitors, setExistingMonitors] = useState<Monitor[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const api_url = process.env.REACT_APP_API_URL;
    const fetchMonitors = async () => {
      try {
        const response = await fetch(`${api_url}/api/monitor`);
        const result = await response.json();
        if (Array.isArray(result.monitors)) {
          setExistingMonitors(result.monitors);
        } else {
          console.error('API response does not contain monitors array:', result);
        }
      } catch (error) {
        console.error('Error fetching monitors:', error);
      }
    };

    fetchMonitors();
  }, []);

  const isValidUrl = (url: string) => {
    return /^(http:\/\/|https:\/\/)/.test(url);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const duplicate = existingMonitors.find(
      (monitor) =>
        monitor.friendly_name === friendlyName || monitor.monitor_url === monitorUrl
    );

    if (duplicate) {
      setError('A monitor with this Friendly Name or URL already exists.');
      setIsModalOpen(true);
      setIsLoading(false);
      return;
    }

    if (!isValidUrl(monitorUrl)) {
      setError('The URL is not valid. Please include http:// or https://');
      setIsModalOpen(true);
      setIsLoading(false);
      return;
    }

    const payload = {
      friendly_name: friendlyName,
      monitor_url: monitorUrl,
      interval,
    };
    const api_url = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(`${api_url}/api/uptime/monitor`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(payload),
      });

      if (response.ok) {
        await response.json();

        setFriendlyName('');
        setMonitorUrl('');
        setInterval(5);
        setIsSubmitted(true);
        setError(null);

        setTimeout(() => {
          setIsSubmitted(false);
          navigate('/monitorminds/list');
        }, 3000);
      } else {
        const errorResult = await response.json();
        setError(errorResult.error ? errorResult.error.message : 'An unknown error occurred.');
        setIsModalOpen(true);
      }
    } catch (err) {
      setError('Request failed.');
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setError(null);
  };

  return (
    <PageLayout>
      <div className="bg-gray-100 min-h-screen py-8"> {/* Set page background to dark gray */}
        <div className="max-w-md mx-auto p-4 sm:p-6 md:p-8 bg-gray-200 rounded-lg shadow-md"> {/* Set content background to lighter gray */}
          <h2 className="text-2xl font-bold mb-4 flex justify-between items-center">
            <span>Create Monitor</span>
            <Link to="/monitorminds/list" className="text-blue-500 hover:underline">
              {/* <FaList className="inline-block mr-1" />
              <span className="hidden sm:inline">View List</span> */}
            </Link>
          </h2>

          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 w-full sm:w-1/3 mb-1 sm:mb-0">Friendly Name</label>
              <input
                type="text"
                value={friendlyName}
                onChange={(e) => setFriendlyName(e.target.value)}
                required
                className="mt-1 block w-full sm:w-2/3 border border-gray-300 rounded-md shadow-sm p-2"
                placeholder="Enter monitor friendly name"
              />
            </div>

            <div className="flex flex-col sm:flex-row sm:items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 w-full sm:w-1/3 mb-1 sm:mb-0">Monitor URL</label>
              <input
                type="url"
                value={monitorUrl}
                onChange={(e) => setMonitorUrl(e.target.value)}
                required
                className="mt-1 block w-full sm:w-2/3 border border-gray-300 rounded-md shadow-sm p-2"
                placeholder="Enter monitor URL"
              />
            </div>

            <div className="flex flex-col sm:flex-row sm:items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 w-full sm:w-1/3 mb-1 sm:mb-0">Interval (minutes)</label>
              <input
                type="number"
                value={interval}
                onChange={(e) => setInterval(Number(e.target.value))}
                min={1}
                required
                className="mt-1 block w-full sm:w-2/3 border border-gray-300 rounded-md shadow-sm p-2"
                placeholder="Enter interval in minutes"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-center">
            <button
              onClick={handleSubmit}
              disabled={isLoading || !friendlyName || !monitorUrl || interval < 1}
              className={`py-2 px-4 rounded-md text-white ${isLoading || !friendlyName || !monitorUrl || interval < 1 ? 'bg-gray-300' : 'bg-blue-500 hover:bg-blue-600'}`}
            >
              {isLoading ? 'Creating...' : 'Create Monitor'}
            </button>
          </div>

          {isSubmitted && (
            <div className="mt-4 p-3 text-center bg-green-200 text-green-700 rounded-md">
              Monitor successfully created!{' '}
              <Link to="/monitorminds/list" className="text-blue-500 hover:underline">
                Go to List
              </Link>
            </div>
          )}

          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded shadow-md max-w-sm w-full m-4">
                <h3 className="text-lg font-bold mb-2">Error</h3>
                <p className="text-red-600">{error}</p>
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={handleCloseModal}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default Create;
