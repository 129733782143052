// index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate"; // Make sure this does not wrap Router

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Auth0ProviderWithNavigate>
      <App /> {/* Only App component is rendered here */}
    </Auth0ProviderWithNavigate>
  </React.StrictMode>
);
