



import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import PageLayout from "../../../components/PageLayout";

// Updated ApiResponse interface with the new `value` field
interface ApiResponse {
  time: string;
  rolling_mean: string;
  rolling_std: string;
  upper_bound: string;
  lower_bound: string;
  residual: string;
  anomaly: string;
  value: string; // New field added
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
}

const AnomalyDetails: React.FC = () => {
  const [formData, setFormData] = useState({
    anomalyType: "Latency", // Default value for anomalyType
    service: "",
    route: "",
    method: "",
  });

  const dropdownOptions = {
    anomalyType: ["Latency", ],
  };

  const [services, setServices] = useState<string[]>([]);
  const [routes, setRoutes] = useState<string[]>([]);
  const [methods, setMethods] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<ApiResponse[]>([]);
  const [showGraph, setShowGraph] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null); // Error state
  const api_url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await fetch(`${api_url}/api/anomaly/dashboard_details`);
        const data = await response.json();
        setServices(data.services || []);
        setRoutes(data.routes || []);
        setMethods(data.methods || []);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
        setError("Error fetching dropdown data.");
      }
    };

    fetchDropdownData();
  }, [api_url]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { anomalyType, service, route, method } = formData;

    if (!service || !route || !method) {
      alert("Please fill in all fields before submitting.");
      return;
    }

    setLoading(true);
    setError(null); // Reset error before making a new request

    try {
      const queryParams = new URLSearchParams({
        services: service,
        methods: method,
        routes: route,
        anomalyType: anomalyType,
      });

      const response = await fetch(`${api_url}/api/anomaly/hit_query?${queryParams.toString()}`, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch anomaly data");
      }

      const data = await response.json();

      if (Array.isArray(data.result)) {
        setGraphData(data.result);
        setShowGraph(true);
      } else {
        console.error("Expected an array but got:", data.result);
        setError("Anomaly data could not be loaded properly.");
      }
    } catch (error) {
      console.error("Table not found:", error);
      setError("Table not found.");
    } finally {
      setLoading(false);
    }
  };

  // Prepare chart data dynamically based on selected route and anomaly type
  const getChartValue = (item: ApiResponse) => {

    // Now return `value` field from the response
    return item.value ? parseFloat(item.value) : 0; // Ensure we always have a number
  };

  const chartData = {
    xAxis: graphData.map((item) => item.time),
    value: graphData.map((item) => getChartValue(item)), // Mapping `value`
    upperBound: graphData.map((item) => parseFloat(item.upper_bound)),
    lowerBound: graphData.map((item) => parseFloat(item.lower_bound)),
    anomaly: graphData.map((item) =>
      item.anomaly.toLowerCase() === "true" ? "True" : "False"
    ),
  };

  const options = {
    title: { text: "Anomaly Detection" },
    tooltip: {
      trigger: "axis",
      formatter: function (params: any) {
        const anomalyStatus = params[3] && params[3].data ? "True" : "False";
        return `  
          <div style="font-size: 14px; color: #333;">
            <div>${params[0]?.axisValue}</div>
            <div>
              <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: blue; margin-right: 4px;"></span>
              Value: ${params[0]?.data}
            </div>
            <div>
              <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: green; margin-right: 4px;"></span>
              Upper Bound: ${params[1]?.data}
            </div>
            <div>
              <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: orange; margin-right: 4px;"></span>
              Lower Bound: ${params[2]?.data}
            </div>
            <div>
              <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: red; margin-right: 4px;"></span>
              Anomaly: ${anomalyStatus}
            </div>
          </div>`; 
      },
    },
    legend: { data: ["Value", "Upper Bound", "Lower Bound", "Anomaly"] },
    xAxis: {
      type: "category",
      data: chartData.xAxis,
      axisLine: {
        lineStyle: {
          color: "#333",
          width: 2,
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#333",
          width: 2,
        },
      },
    },
    series: [
      { name: "Value", type: "line", data: chartData.value, smooth: true },
      { name: "Upper Bound", type: "line", data: chartData.upperBound, smooth: true },
      { name: "Lower Bound", type: "line", data: chartData.lowerBound, smooth: true },
      {
        name: "Anomaly",
        type: "scatter",
        data: chartData.anomaly.map((anomaly, index) =>
          anomaly === "True"
            ? { value: chartData.value[index], itemStyle: { color: "#ff0000" } }
            : null
        ),
        symbolSize: 10,
      },
    ],
  };

  return (
    <PageLayout>
      <div className="p-4 bg-gray-100 min-h-screen">
        <form onSubmit={handleSubmit} className="w-full max-w-6xl mx-auto text-align-left">
          <h1 className="text-3xl font-bold text-gray-800 mb-6 text-start">Anomaly</h1>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
            {/* Anomaly Type */}
            <div>
              <label htmlFor="anomalyType" className="block text-sm font-bold text-gray-700 mb-1">
                Anomaly Type
              </label>
              <select
                id="anomalyType"
                name="anomalyType"
                value={formData.anomalyType}
                onChange={handleSelectChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Anomaly Type</option>
                {dropdownOptions.anomalyType.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Service */}
            <div>
              <label htmlFor="service" className="block text-sm font-bold text-gray-700 mb-1">
                Service
              </label>
              <select
                id="service"
                name="service"
                value={formData.service}
                onChange={handleSelectChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Service</option>
                {services.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Method */}
            <div>
              <label htmlFor="method" className="block text-sm font-bold text-gray-700 mb-1">
                Method
              </label>
              <select
                id="method"
                name="method"
                value={formData.method}
                onChange={handleSelectChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Method</option>
                {methods.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Route */}
            <div>
              <label htmlFor="route" className="block text-sm font-bold text-gray-700 mb-1">
                Route
              </label>
              <select
                id="route"
                name="route"
                value={formData.route}
                onChange={handleSelectChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Route</option>
                {routes.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-4 text-end">
            <button
              type="submit"
              className="py-2 px-6 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
            >
              Hit Query
            </button>
          </div>
        </form>

        <div className="mt-6 relative">
          {loading ? (
            <div className="flex justify-center items-center h-40">
              <div className="spinner-border animate-spin border-4 border-t-4 border-blue-500 rounded-full h-8 w-8"></div>
            </div>
          ) : error ? (
            <div className="text-red-600 text-center">{error}</div> // Error message in the graph area
          ) : (
            showGraph && <ReactECharts option={options} />
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default AnomalyDetails;


