

import React, { useEffect, useState } from 'react';
import PageLayout from '../../../components/PageLayout'; // Import your PageLayout component
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


// Interfaces (no changes here)
interface ResourceReport {
  created_at: string;
  created_by_token: string | null;
  filter: string | null;
  title: string;
  token: string;
  user_token: string | null;
  workspace_token: string;
}

interface ResourceCost {
  amount: string;
  category: string;
}

interface ActiveResource {
  account_id: string;
  billing_account_id: string;
  costs: ResourceCost[];
  created_at: string;
  label: string;
  metadata: {
    version: number;
  };
  provider: string;
  region: string;
  token: string;
  type: string;
  uuid: string;
}

interface SingleResource extends ActiveResource { }

interface PaginationLinks {
  next?: string;
  prev?: string;
  self?: string;
  last?: string;
}

const ActiveResourceReports: React.FC = () => {
  const [resourceReports, setResourceReports] = useState<ResourceReport[]>([]);
  const [activeResources, setActiveResources] = useState<ActiveResource[]>([]);
  const [selectedResource, setSelectedResource] = useState<SingleResource | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [loadingActiveResources, setLoadingActiveResources] = useState<boolean>(false);
  const [errorActiveResources, setErrorActiveResources] = useState<string | null>(null);
  const [isActiveResourcesOpen, setIsActiveResourcesOpen] = useState<boolean>(false);
  const [paginationLinks, setPaginationLinks] = useState<PaginationLinks | null>(null);
  const [totalCosts, setTotalCosts] = useState<number | null>(null);
  const [totalResources, setTotalResources] = useState<number | null>(null);

  const api_url = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  useEffect(() => {
    const fetchResourceReports = async () => {
      try {
        const response = await fetch(`${api_url}/api/vantage/resource_reports`);
        if (!response.ok) {
          throw new Error('Failed to fetch resource reports');
        }
        const data = await response.json();
        setResourceReports(data.resource_reports || []);
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    fetchResourceReports();
  }, [api_url]);

  const fetchActiveResources = async (url: string) => {
    setLoadingActiveResources(true);
    setErrorActiveResources(null);
    try {
      const updatedUrl = url.replace('https://api.vantage.sh/v2', `${api_url}/api/vantage`);
      const response = await fetch(updatedUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch active resources');
      }
      const data = await response.json();
      setActiveResources(data.resources || []);
      setPaginationLinks(data.links || {});
      // setTotalCosts(data.total_costs || null);
      // setTotalResources(data.total_resources || null);
       // Update the global totals across pages
    setTotalCosts((prevTotal) => (prevTotal || 0) + (data.total_costs || 0));
    setTotalResources((prevTotal) => (prevTotal || 0) + (data.total_resources || 0));

    } catch (error) {
      setErrorActiveResources(error instanceof Error ? error.message : 'Unknown error');
    } finally {
      setLoadingActiveResources(false);
    }
  };
  const fetchTotals = async (reportToken: string) => {
    try {
      const response = await fetch(`${api_url}/api/vantage/resource_total?resource_report_token=${reportToken}`);
      if (!response.ok) {
        throw new Error('Failed to fetch total costs and total resources');
      }
      const data = await response.json();
      setTotalCosts(data.total_costs || 0);
      setTotalResources(data.total_resources || 0);
    } catch (error) {
      console.error('Error fetching total costs and resources:', error);
    }
  };
  
  const fetchSingleResource = async (token: string) => {
    try {
      const response = await fetch(`${api_url}/api/vantage/resources/${token}?include_cost=true`);
      if (!response.ok) {
        throw new Error('Failed to fetch resource details');
      }
      const data = await response.json();
      setSelectedResource(data);
    } catch (error) {
      console.error('Error fetching single resource:', error);
    }
  };

  
  const handleActiveResourcesClick = (reportToken: string) => {
    const initialUrl = `${api_url}/api/vantage/resources?resource_report_token=${reportToken}&include_cost=true&page=1&limit=25`;
    fetchActiveResources(initialUrl);
    setIsActiveResourcesOpen((prev) => !prev);
    fetchTotals(reportToken); // Fetch total costs and resources
  };

  const handleLabelClick = async (resourceToken: string) => {
    await fetchSingleResource(resourceToken);
  };

  const handlePageChange = (pageUrl: string | null) => {
    if (pageUrl) {
      fetchActiveResources(pageUrl); // This will accumulate totals
      
    }
  };

  const getNextPageUrl = () => {
    return paginationLinks?.next || '';
  };

  const getPrevPageUrl = () => {
    return paginationLinks?.prev || '';
  };

  return (
    <PageLayout>
      <div className="flex flex-col min-h-screen bg-gray-100">
        {/* Main content */}
        <div className="flex-1">
          <div className="p-4 bg-gray-200 rounded-lg shadow-md max-w-6xl mx-auto mt-6 transition-all duration-500">
            <Typography variant="h4" className="text-3xl font-semibold mb-4">Active Resource Reports</Typography>

            {loading ? (
              <CircularProgress />
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <TableContainer className="bg-gray-300 rounded-lg transition-all duration-500">
                <Table>
                  <TableHead className="bg-gray-400">
                    <TableRow>
                      <TableCell className="font-semibold !text-lg">Title</TableCell>
                      <TableCell className="font-semibold !text-lg">Created At</TableCell>
                      <TableCell className="font-semibold !text-lg">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resourceReports.map((report) => (
                      <TableRow key={report.token}>
                        <TableCell>{report.title}</TableCell>
                        <TableCell>{new Date(report.created_at).toLocaleDateString()}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => handleActiveResourcesClick(report.token)}
                            color="primary"
                          >
                            {isActiveResourcesOpen ? 'Close Active Resources' : 'View Active Resources'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {isActiveResourcesOpen && activeResources.length > 0 && (
              <div className="mt-6">
                <Typography variant="h6" className="font-semibold mb-4">Active Resources</Typography>
            
                <div className="mb-4">
                  
                  <Typography variant="body1">Total Costs: ${totalCosts?.toFixed(2)}</Typography>
                  <Typography variant="body1">Total Resources : {totalResources}</Typography>
                </div>
                {loadingActiveResources ? (
                  <CircularProgress />
                ) : errorActiveResources ? (
                  <Typography color="error">{errorActiveResources}</Typography>
                ) : (
                  <TableContainer className="bg-gray-300 rounded-lg transition-all duration-500">
                    <Table>
                      <TableHead className="bg-gray-400 " >
                        <TableRow >
                          <TableCell className="font-semibold !text-lg">Label</TableCell>
                          <TableCell className="font-semibold !text-lg">Provider</TableCell>
                          <TableCell className="font-semibold !text-lg">Region</TableCell>
                          <TableCell className="font-semibold !text-lg">Cost</TableCell>
                          <TableCell className="font-semibold !text-lg">Created At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {activeResources.map((resource) => (
                          <TableRow key={resource.uuid} className="transition-all duration-300 hover:bg-gray-400">
                            <TableCell>
                              <Button className='text-start'

                                onClick={() => handleLabelClick(resource.token)}
                                color="primary"
                              >
                                {resource.label}
                              </Button>
                            </TableCell>
                            <TableCell>{resource.provider}</TableCell>
                            <TableCell>{resource.region}</TableCell>
                            <TableCell>
                              {resource.costs.map((cost, index) => (
                                <div key={index}>
                                  {cost.category}: ${cost.amount}
                                </div>
                              ))}
                            </TableCell>
                            <TableCell>{new Date(resource.created_at).toLocaleDateString()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <div className="mt-4 flex justify-between">
                  <Button
                    onClick={() => handlePageChange(getPrevPageUrl())}
                    disabled={!paginationLinks?.prev}
                    variant="contained"
                    color="primary"
                  >
                    Previous
                  </Button>
                  <Typography variant="body1">
                    Page {paginationLinks?.self ? new URL(paginationLinks.self).searchParams.get('page') : 1} of {paginationLinks?.last ? new URL(paginationLinks.last).searchParams.get('page') : 1}
                  </Typography>
                  <Button
                    onClick={() => handlePageChange(getNextPageUrl())}
                    disabled={!paginationLinks?.next}
                    variant="contained"
                    color="primary"
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>

        <Dialog open={Boolean(selectedResource)} onClose={() => setSelectedResource(null)}>
          <DialogTitle>Resource Details</DialogTitle>
          <DialogContent>
            {selectedResource && (
              <div>
                <Typography variant="h6">{selectedResource.label}</Typography>
                <Typography variant="body1">Provider: {selectedResource.provider}</Typography>
                <Typography variant="body1">Region: {selectedResource.region}</Typography>
                <Typography variant="body1">Created At: {new Date(selectedResource.created_at).toLocaleDateString()}</Typography>
                <Typography variant="body1">Cost: ${selectedResource.costs.reduce((sum, cost) => sum + parseFloat(cost.amount), 0).toFixed(2)}</Typography>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedResource(null)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </PageLayout>
  );
};

export default ActiveResourceReports;






