



import React, { useEffect, useState, useCallback } from "react";
import ReactECharts from "echarts-for-react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";
import PageLayout from "../../../components/PageLayout";
import debounce from "lodash.debounce";


interface CostData {
  amount: string;
  average_daily_spend: number;
  currency: string;
  month: string;
}

interface ForecastedCost {
  amount: number;
  currency: string;
}

interface GroupedCost {
  date: string;
  total_amount: number;
  services: ServiceCost[];
}

interface ServiceCost {
  service: string;
  total_cost: number;
}

const api_url = process.env.REACT_APP_API_URL || "http://localhost:3000";

const CostList: React.FC = () => {
  const [currentMonthCost, setCurrentMonthCost] = useState<CostData | null>(null);
  const [forecastedCost, setForecastedCost] = useState<ForecastedCost | null>(null);
  const [groupedCosts, setGroupedCosts] = useState<GroupedCost[]>([]);
  const [totalAmount, setTotalAmount] = useState<number | null>(null);
  const [serviceCosts, setServiceCosts] = useState<Record<string, number>>({});
  const [previousMonthTotalCost, setPreviousMonthTotalCost] = useState<CostData | null>(null);
  const [, setPreviousMonthForecastedCost] = useState<ForecastedCost | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Pagination state
  const [page, setPage] = useState(0);
  const rowsPerPage = 10; // Show 10 items per page

  // Sorting state
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  // Date range state
  const [startDate, setStartDate] = useState<string>(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2, 1);
    return firstDayOfMonth.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split("T")[0]);

  // Function to fetch data
  const fetchCosts = async () => {
    try {

      const today = new Date();

      // Fetch current month data (December)
      const currentMonthResponse = await fetch(
        `${api_url}/api/vantage/costs?cost_report_token=rprt_2872f1d4ba6fe155&start_date=${startDate}&end_date=${endDate}&groupings=service`
      );
      const currentMonthData = await currentMonthResponse.json();

      // Fetch previous month's data (November)
      const previousMonthStart = new Date(today.getFullYear(), 10, 1);
      const previousMonthEnd = new Date(today.getFullYear(), 10, 30);
      const previousMonthStartdate = previousMonthStart.toISOString().split("T")[0];
      const previousMonthEnddate = previousMonthEnd.toISOString().split("T")[0];

      const previousMonthResponse = await fetch(
        `${api_url}/api/vantage/costs?cost_report_token=rprt_2872f1d4ba6fe155&start_date=${previousMonthStartdate}&end_date=${previousMonthEnddate}&groupings=service`
      );
      const previousMonthData = await previousMonthResponse.json();

      // Set previous month data (November)
      setPreviousMonthTotalCost({
        amount: previousMonthData.total_cost.amount,
        average_daily_spend: previousMonthData.total_cost.average_daily_spend,
        currency: previousMonthData.total_cost.currency,
        month: "November",
      });
      setPreviousMonthForecastedCost(previousMonthData.forecasted_cost);

      // Set current month data (December)
      setCurrentMonthCost({
        amount: currentMonthData.total_cost.amount,
        average_daily_spend: currentMonthData.total_cost.average_daily_spend,
        currency: currentMonthData.total_cost.currency,
        month: "December",
      });
      setForecastedCost(currentMonthData.forecasted_cost);

      if (Array.isArray(currentMonthData.grouped_costs)) {
        setGroupedCosts(currentMonthData.grouped_costs);
      } else {
        setGroupedCosts([]);
      }

      if (currentMonthData.total_amount) {
        setTotalAmount(parseFloat(currentMonthData.total_amount));
      }

      const currentMonthServiceCosts = calculateMonthlyServiceCosts(currentMonthData.grouped_costs);
      setServiceCosts(currentMonthServiceCosts);

    } catch (error) {
      console.error("Error fetching cost data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateMonthlyServiceCosts = (groupedCosts: GroupedCost[]) => {
    return groupedCosts.reduce((acc: Record<string, number>, cost) => {
      cost.services.forEach((service) => {
        if (!acc[service.service]) {
          acc[service.service] = 0;
        }
        acc[service.service] += service.total_cost;
      });
      return acc;
    }, {} as Record<string, number>);
  };

  // Debounced version of fetchCosts
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCostsDebounced = useCallback(
    debounce(() => {
      fetchCosts();
    }, 500),
    []
  );

  useEffect(() => {
    fetchCostsDebounced();
    // Cleanup on unmount
    return () => fetchCostsDebounced.cancel();
  }, [startDate, endDate, fetchCostsDebounced]);

  const sortedGroupedCosts = [...groupedCosts].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const chartData = sortedGroupedCosts.reduce(
    (acc: { dates: string[]; amounts: number[] }, cost) => {
      acc.dates.push(cost.date);
      acc.amounts.push(cost.total_amount);
      return acc;
    },
    { dates: [], amounts: [] }
  );

  const chartOptions = {
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: chartData.dates,
      name: "Date",
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: "value",
      name: "Cost (USD)",
    },
    series: [
      {
        data: chartData.amounts,
        type: "bar",
        name: "Daily Cost",
        itemStyle: {
          color: "#3a85ff",
        },
      },
      ...(totalAmount !== null
        ? [
          {
            type: "line",
            name: "Total Amount",
            data: [],
            markLine: {
              symbol: "none",
              label: {
                formatter: `Total Amount: ${totalAmount}`,
                position: "end",
                color: "#ff0000",
              },
              lineStyle: {
                type: "dashed",
                color: "#ff0000",
              },
              data: [
                {
                  yAxis: totalAmount,
                },
              ],
            },
          },
        ]
        : []),
    ],
    legend: {
      data: ["Daily Cost", ...(totalAmount !== null ? ["Total Amount"] : [])],
    },
  };

  // Pagination handlers
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Sorting handler
  const handleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
  };



  // Calculate paginated and sorted data
  const paginatedServiceCosts = Object.entries(serviceCosts)
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage);



  return (
    <PageLayout>
      <Container maxWidth="lg">
        <Box className="p-6 bg-gray-100" style={{ height: "100vh", overflow: "auto" }}>
          <Typography variant="h4" gutterBottom>
            Understand Spend Trends
          </Typography>

          <Grid container spacing={4} alignItems="stretch">
            {previousMonthTotalCost && (
              <Grid item xs={12} sm={4}>
                <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
                  <Typography variant="h6">{previousMonthTotalCost.month} Total Cost</Typography>
                  <Typography variant="body1">
                    ${parseFloat(previousMonthTotalCost.amount).toFixed(2)} {previousMonthTotalCost.currency}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {previousMonthTotalCost.average_daily_spend.toFixed(2)} Avg. Daily Spend
                  </Typography>
                </Paper>
              </Grid>
            )}
            {currentMonthCost && (
              <Grid item xs={12} sm={4}>
                <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
                  <Typography variant="h6">{currentMonthCost.month} Total Cost</Typography>
                  <Typography variant="body1">
                    ${parseFloat(currentMonthCost.amount).toFixed(2)} {currentMonthCost.currency}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentMonthCost.average_daily_spend.toFixed(2)} Avg. Daily Spend
                  </Typography>
                </Paper>
              </Grid>
            )}
            {forecastedCost && (
              <Grid item xs={12} sm={4}>
                <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
                  <Typography variant="h6">Forecasted Cost</Typography>
                  <Typography variant="body1">
                    ${forecastedCost.amount.toFixed(2)} ({forecastedCost.currency})
                  </Typography>
                </Paper>
              </Grid>
            )}
          </Grid>

          {/* Date Range Picker */}
          <Grid container spacing={2} alignItems="center" style={{ marginTop: "40px", marginBottom: "20px", justifyContent: "flex-end" }}>
            <Grid item>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  setIsLoading(true); // Set loading to true before triggering fetch
                  fetchCosts();
                }}
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Hit Query"
                )}
              </Button>
            </Grid>
          </Grid>

          <Box mt={4}>
            <ReactECharts option={chartOptions} style={{ height: "400px", width: "100%" }} />
          </Box>

          <Typography variant="h4" gutterBottom style={{ marginTop: "40px" }}>
            Services
          </Typography>
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table>
              <TableHead className="bg-gray-300">
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell>
                    Total Cost
                    <Button onClick={handleSort} style={{ marginLeft: '8px' }}>
                      {sortOrder === 'asc' ? '▲' : '▼'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedServiceCosts
                  .sort(([, a], [, b]) => sortOrder === 'asc' ? a - b : b - a)
                  .map(([service, cost]) => (
                    <TableRow key={service}>
                      <TableCell>{service}</TableCell>
                      <TableCell>{typeof cost === 'number' ? cost.toFixed(2) : cost}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* New Pagination Component */}
          <div className="flex items-center justify-center gap-2 mt-4 mb-8">
            <button
              onClick={() => handleChangePage(null, page - 1)}
              disabled={page === 0}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
            >
              PREVIOUS
            </button>

            {Array.from({ length: Math.min(7, Math.ceil((Object.entries(serviceCosts).length - 1) / rowsPerPage)) }).map((_, index) => (
              <button
                key={index}
                onClick={() => handleChangePage(null, index)}
                className={`px-4 py-2 text-sm font-medium rounded-md ${page === index
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50'
                  }`}
              >
                {index + 1}
              </button>
            ))}

            {Math.ceil((Object.entries(serviceCosts).length - 1) / rowsPerPage) > 7 && (
              <>
                <span className="px-2">...</span>
                <button
                  onClick={() => handleChangePage(null, Math.ceil((Object.entries(serviceCosts).length - 1) / rowsPerPage) - 1)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  {Math.ceil((Object.entries(serviceCosts).length - 1) / rowsPerPage)}
                </button>
              </>
            )}

            <button
              onClick={() => handleChangePage(null, page + 1)}
              disabled={page >= Math.ceil((Object.entries(serviceCosts).length - 1) / rowsPerPage) - 1}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
            >
              NEXT
            </button>
          </div>
        </Box>
      </Container>
    </PageLayout>
  );
};

export default CostList;

