


import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../styles/Header.css"; // External CSS for styling

 

const Header: React.FC = () => {
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  return (
    <header className="header">
      <div className="title-container">
        <img
          src="/favicon-32x32.png" // Path to the image in the public folder
          alt="CB-VISTA Logo"
          className="logo-image"
        />
        <h1 className="title">CB-VISTA</h1>
      </div>
      <div className="auth-container">
        {isAuthenticated ? (
          <div className="profile-container">
            <img
              src={user?.picture || "https://via.placeholder.com/40"} // Placeholder if no image
              alt="Profile"
              className="profile-image"
              onClick={toggleDropdown} // Toggle dropdown
            />
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <div className="dropdown-header">
                  <img
                    src={user?.picture || "https://via.placeholder.com/40"}
                    alt="Profile"
                    className="dropdown-profile-image"
                  />
                  <span title={user?.name || "User"}>
                    {user?.name || "User"}
                  </span>{" "}
                  {/* Adds a tooltip for full name */}
                </div>
                <hr />
                <div>
                  <button
                    className="dropdown-item"
                    onClick={() =>
                      (window.location.href = "/profile")
                    }
                  >
                    Profile
                  </button>
                </div>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <button className="login-button" onClick={() => loginWithRedirect()}>
            Login
          </button>
        )}
       
        
      </div>
    </header>
  );
};

export default Header;


