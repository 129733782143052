import React, { PropsWithChildren } from "react";
import Sidebar from "./sidebar";
import Header from "./Header";
import "../styles/pagelayout.css"; // Include styles for layout


const PageLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <><Header /><div className="flex">
      <Sidebar />
      <div className="flex-1 flex flex-col">

        <main className="p-4 ">{children}</main> {/* Render children here */}
      </div>

    </div></>
  );
};

export default PageLayout;



